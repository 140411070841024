<template>
  <div style="margin: 20px 0">
    <el-row>
      <el-col :span="16">
        <el-button
          size="small"
          type="primary"
          @click="showFilter = !showFilter"
        >
          Filter
        </el-button>
      </el-col>
    </el-row>
  </div>
  <div style="margin: 20px 0" v-if="showFilter">
    <!-- Filter Form -->
    <el-form>
      <el-card shadow="hover">
        <el-row gutter="20">
          <el-form-item label="Target Order ID">
            <el-input v-model="orderId"></el-input>
          </el-form-item>
        </el-row>

        <el-row gutter="20">
          <el-form-item label="Hub Order ID">
            <el-input v-model="hubId"></el-input>
          </el-form-item>
        </el-row>

        <el-row :gutter="20">
          <el-button size="small" type="success" @click="getSearchData()">
            Search
          </el-button>
          <el-button size="small" type="primary" @click="handleClear()">
            Clear
          </el-button>
        </el-row>
      </el-card>
    </el-form>
  </div>

  <!-- List View -->
  <el-card
    v-for="item in listData"
    :key="item.id"
    shadow="hover"
    style="margin: 10px 0"
  >
    <div>
      <p>Target Order ID: {{ item.targetOrderId }}</p>
      <p>Hub Order ID: {{ item.hubOrderId }}</p>
      <p>
        Target Name:
        {{ item.connectionTarget ? item.connectionTarget.name : '' }}
      </p>
      <p>
        Status:
        <span v-if="item.completed">
          <el-button
            type="success"
            icon="el-icon-check"
            circle
            size="small"
          ></el-button>
        </span>
        <span v-else>
          <el-button
            v-if="item.numberOfRetry < 5"
            type="warning"
            icon="el-icon-close"
            circle
            size="small"
          ></el-button>
          <el-button
            v-else
            type="danger"
            icon="el-icon-close"
            circle
            size="small"
          ></el-button>
        </span>
      </p>
      <p>Timestamp: {{ item.timeStamp }}</p>
      <div
        style="
          white-space: nowrap;
          overflow: scroll;
          /* width: 240px; */
          display: inline-block;
          padding-bottom: 10px;
        "
      >
        Sync Error: {{ item.message }}
      </div>

      <!-- Add any other fields you want to display -->
      <el-button size="small" @click="viewDetail(item)" type="primary"
        >View Detail</el-button
      >
    </div>
  </el-card>

  <!-- Order Detail Dialog -->
  <el-dialog title="Order Detail" v-model="dialogOrderDetail" fullscreen>
    <order-detail-modal :item="dataItem" />
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogOrderDetail = false"
          >Cancel</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { Options, Vue } from 'vue-class-component'
import axios from '@/plugins/axios'
import OrderDetailModal from './OrderDetailModal.vue'

@Options({
  components: {
    OrderDetailModal
  },
  props: {
    listData: []
  }
})
export default class OrderTable extends Vue {
  name = 'Order Table'
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  dataItem = {}
  dialogOrderDetail = false
  viewDetail(row) {
    this.dataItem = row
    this.dialogOrderDetail = true
  }
  connectData = {}
  hubOrderId = ''
  targetOrderIds = []
  target = ''
  showFilter = false
  orderId = ''
  hubId = ''
  condition = {
    completed: false
  }
  listStatus = [
    { label: 'All', value: undefined },
    { label: 'Completed', value: true },
    { label: 'Failed', value: false }
  ]

  async created() {
    await this.getListConnect()
    const targetOrderId = this.$route.query.targetOrderId
    if (targetOrderId) {
      this.orderId = targetOrderId
      this.getSearchData()
      this.$watch(
        'listData',
        (newVal) => {
          if (newVal.length && this.$route.query.targetOrderId) {
            this.$router.push('/sync-errors')
            this.viewDetail(newVal[0])
          }
        },
        { immediate: true, deep: true }
      )
    }
  }
  async getListConnect() {
    let res = await axios.get('user/connect/list', {
      headers: this.headers
    })
    if (res.data) {
      this.connectData = res.data
      for (let connect of this.connectData) {
        if (connect.connectFrom) {
          this.hubOrderId = connect.type + 'OrderId'
        } else {
          if (this.targetOrderIds.indexOf(connect.type + 'OrderId') == -1) {
            this.targetOrderIds.push(connect.type + 'OrderId')
          }
        }
      }
    }
  }
  async getSearchData() {
    this.condition['$or'] = undefined
    this.condition['$and'] = undefined
    if (this.orderId.length > 0 && this.hubId.length > 0) {
      this.condition['$and'] = [
        {
          $or: []
        },
        {
          [this.hubOrderId]: { $regex: this.hubId, $options: 'i' }
        }
      ]
      for (let id of this.targetOrderIds) {
        this.condition['$and'][0]['$or'].push({
          [id]: { $regex: this.orderId, $options: 'i' }
        })
      }
    } else {
      this.condition['$or'] = []
      if (this.orderId.length > 0) {
        for (let id of this.targetOrderIds) {
          this.condition['$or'].push({
            [id]: { $regex: this.orderId, $options: 'i' }
          })
        }
      }
      if (this.hubId.length > 0) {
        this.condition['$or'].push({
          [this.hubOrderId]: { $regex: this.hubId, $options: 'i' }
        })
      }
      if (this.orderId.length == 0 && this.hubId.length == 0) {
        delete this.condition['$or']
      }
    }
    let data = {
      condition: this.condition
    }
    this.$emit('search', data)
  }
  handleClear() {
    this.condition.completed = undefined
    this.condition['$or'] = undefined
    this.condition['$and'] = undefined
    this.orderId = ''
    this.hubId = ''
    this.getSearchData()
  }
}
</script>
<style></style>
